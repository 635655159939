<template>
  <en-table-layout
    toolbar
    pagination
    :tableData="pageData.data"
    :loading="loading">
    <div slot="toolbar" class="inner-toolbar">
      <!-- <div class="toolbar-btns">
        <span>店铺分组：</span>
        <en-category-picker @changed="categoryChanged" :clearable='true'/>
      </div> -->
      <div class="toolbar-search">
        <en-table-search @search="searchEvent" placeholder="请输入商品名称"/>
      </div>
    </div>
    <template slot="table-columns">
      <el-table-column label="图片" width="60" class-name="goods-cover-wrapper">>
        <template slot-scope="{row}">
          <el-popover
            placement="right"
            trigger="hover"
          >
            <img :src="row.thumbnail" alt="" style="width: 300px">
            <img :src="row.thumbnail" class="goods-cover" alt="" slot="reference"/>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="goods_name" label="商品名称"/>
      <el-table-column label="价格" width="120">
        <template slot-scope="scope">{{ scope.row.price | unitPrice('￥') }}</template>
      </el-table-column>
      <el-table-column label="库存" width="120">
        <template slot-scope="scope">{{ scope.row.quantity }}件</template>
      </el-table-column>
      <el-table-column label="创建时间" width="160">
        <template slot-scope="scope">{{ scope.row.create_time | unixToDate('yyyy-MM-dd hh:mm') }}</template>
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button
            type="success"
            @click="handleDraftEdit(scope.row)">编辑
          </el-button>
          <el-button
            type="danger"
            @click="handleDraftDel(scope.row)">删除
          </el-button>
        </template>
      </el-table-column>
    </template>
    <el-pagination
      slot="pagination"
      @size-change="handlePageSizeChange"
      @current-change="handlePageCurrentChange"
      :current-page="pageData.page_no"
      :page-sizes="MixinPageSizes"
      :layout="MixinTableLayout"
      background
      :page-size="pageData.page_size"
      :total="pageData.data_total">
    </el-pagination>
  </en-table-layout>
</template>

<script>
import * as API_goods from '@/api/goods'
import {CategoryPicker} from '@/components'
import EnTableLayout from '../../../ui-components/TableLayout/src/main';

export default {
  name: 'draftList',
  components: {
    EnTableLayout,
    [CategoryPicker.name]: CategoryPicker
  },
  data() {
    return {
      /** 列表loading状态 */
      loading: false,

      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20
      },

      /** 列表分页数据 */
      pageData: {data: []}
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.GET_DraftGoodsList()
      next()
    })
  },
  methods: {

    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size
      this.GET_DraftGoodsList()
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page
      this.GET_DraftGoodsList()
    },

    /** 搜索事件触发 */
    searchEvent(data) {
      this.params = {
        ...this.params,
        keyword: data
      }
      delete this.params.category_path
      this.GET_DraftGoodsList()
    },

    /** 分类选择组件值发生改变 */
    categoryChanged(data) {
      delete this.params.keyword
      delete this.params.shop_cat_path
      if (data && Array.isArray(data) && data.length !== 0) {
        this.params = {
          ...this.params,
          shop_cat_path: '0|' + data.join('|') + '|'
        }
      }
      this.GET_DraftGoodsList()
    },

    GET_DraftGoodsList() {
      this.loading = true
      API_goods.getDraftGoodsList(this.params).then(response => {
        this.loading = false;
        this.pageData = response;
        this.pageData.data = this.pageData.data.map(key => {
          if (!key.thumbnail && key.gallery_list) {
            key.thumbnail = key.gallery_list[0]
          }
          return key;
        })
      })
    },

    /** 草稿箱编辑 */
    handleDraftEdit(row) {
      const _draft_goods_id = row.draft_goods_id || '0'
      this.$router.push({name: 'goodPublish',
        params: {
          goodsid: _draft_goods_id,
          isdraft: 2,
          callback: this.GET_DraftGoodsList
        }
      })
    },

    /** 草稿箱商品删除 */
    handleDraftDel(row) {
      this.$confirm('确认删除此草稿箱商品, 是否继续?', '提示', {type: 'warning'}).then(() => {
        API_goods.deleteDraftGoods(row.draft_goods_id, {}).then(() => {
          this.GET_DraftGoodsList()
          this.$message.success('删除草稿箱商品成功！')
        })
      }).catch(() => {
        this.$message.info({message: '已取消删除'})
      })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-table td:not(.is-left) {
  text-align: center;
}

/** 工具条 */
/deep/ div.toolbar {
  height: 70px;
  padding: 20px 0;
}

.inner-toolbar {
  display: flex;
  width: 100%;
  justify-content: space-between;

  .toolbar-btns {
    span {
      display: inline-block;
      font-size: 14px;
      color: #606266;
    }
  }

  .toolbar-search {
    margin-right: 10px;
  }
}

/*商品图片*/
.goods-image {
  width: 50px;
  height: 50px;
}

</style>
